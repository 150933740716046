<template lang="pug">
.page
  el-card.filterCard
    el-form(inline label-width="6em")
      el-form-item(label="搜索")
        el-input(v-model="filter.search")
      el-form-item()
        el-button(type="primary" icon="el-icon-search" @click="handleSearch") 搜索
        el-button(type="warning" icon="el-icon-refresh" @click="initData") 重置
        el-button(type="success" icon="el-icon-plus" @click="handleAdd") 添加新账号
  el-card.tableCard
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(label="头像")
        template(#default="scope")
          .avatarContainer(v-bg.cover="scope.row.extendInfo?.avatar")
      el-table-column(prop="account" label="账号")
      el-table-column(prop="phone" label="手机")
      el-table-column(prop="extendInfo.trueName" label="姓名")
      el-table-column(fixed="right" label="操作" width="120")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)") 编辑
          //- el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain)
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="handleSearch"
        @current-change="getList")

  el-dialog(v-model="isShowEditForm")
    el-form(label-width="6em")
      el-form-item(label="id" v-if="editFormData._id")
        el-input(v-model="editFormData._id" disabled)
      el-form-item(label="账号")
        el-input(v-model="editFormData.account" :disabled="!!editFormData._id")
      el-form-item(label="手机号")
        el-input(v-model="editFormData.phone")
      el-form-item(label="姓名")
        el-input(v-model="editFormData.extendInfo.trueName")
      el-form-item(label="头像")
        jj-imagePick(v-model="editFormData.extendInfo.avatar" type="account")
      el-form-item(label="初始密码" v-if="!editFormData._id")
        el-input(v-model="editFormData.password")
      el-form-item()
        el-button(type="primary" @click="handleSubmitEdit") 确定

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const { fetch, router, message } = inject('global')

const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const filter = ref({
  search: ''
})

function handleSearch() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function initData() {
  filter.value.search = ''
  handleSearch()
}
function getList() {
  let params = {
    ...pagination.value
  }
  if (filter.value.search) {
    params.search = filter.value.search
  }
  fetch.get('/account', { params }).then(res => {
    list.value = res.list
    pagination.value.total = res.page.total
  })
}

const isShowEditForm = ref(false)
const editFormData = ref({
  _id: '',
  account: '',
  phone: '',
  password: '',
  extendInfo: {
    avatar: '',
    trueName: ''
  }
})

function handleAdd() {
  editFormData.value = {
    _id: '',
    account: '',
    phone: '',
    password: '',
    extendInfo: {
      avatar: '',
      trueName: ''
    }
  }
  isShowEditForm.value = true
}

function handleEdit(val) {
  editFormData.value = val
  isShowEditForm.value = true
}
function handleSubmitEdit() {
  return Promise.resolve()
    .then(() => {
      if (editFormData.value._id) {
        return fetch.put(`/account/${editFormData.value._id}`, editFormData.value)
      } else {
        return fetch.post(`/account`, editFormData.value)
      }
    })
    .then(res => {
      isShowEditForm.value = false
      message.success('保存成功')
      initData()
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.avatarContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px var(--color-border);
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
